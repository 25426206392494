<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="px-0 d-flex justify-space-between align-center">
        <v-btn
          color="primary"
          outlined
          depressed
          :ripple="false"
          style="width: fit-content; background-color: white;"
          @click="onBack()">
          <v-icon>
            mdi-chevron-left
          </v-icon>
          <span>
            ย้อนกลับ
          </span>
        </v-btn>
        <v-btn
          color="primary"
          outlined
          depressed
          :ripple="false"
          :loading="exporting"
          style="width: fit-content; background-color: white;"
          @click="onExport()">
          <v-icon class="mr-2">
            mdi-tray-arrow-up
          </v-icon>
          <span>
            นำออกข้อมูล
          </span>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-row class="form-container rounded-lg">
          <v-col cols="12">
            <report-filters
              v-model="query"
              @search="getItems(true)" />
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :sort-by.sync="query.sortBy"
              :sort-desc.sync="query.sortOrder"
              hide-default-footer
              class="table-border">
              <template #[`item.vendorNo`]="{ item }">
                <v-btn
                  class="px-0"
                  style="letter-spacing: 0;"
                  color="primary"
                  plain
                  :ripple="false"
                  :disabled="loading"
                  @click="showDetail(item)">
                  {{ item.vendorNo }}
                </v-btn>
              </template>
              <template #[`item.tourAmount`]="{ item }">
                {{ (item.tourAmount || 0) | showNumberFormat() }}
              </template>
              <template #[`item.orderAmount`]="{ item }">
                {{ (item.orderAmount || 0) | showNumberFormat() }}
              </template>
              <template #[`item.orderSales`]="{ item }">
                ฿ {{ (item.orderSales || 0) | showNumberFormat() }}
              </template>
              <template #[`item.status`]="{ item }">
                <span
                  v-if="item.status === 'available'"
                  class="success--text">
                  เปิดใช้งาน
                </span>
                <span
                  v-else-if="item.status === 'unavailable'"
                  class="accent--text">
                  ปิดการใช้งาน
                </span>
                <span
                  v-else-if="item.status === 'inspecting'"
                  class="warning--text">
                  รอตรวจสอบ
                </span>
              </template>
              <!-- <template #[`item.actions`]="{ item }">
                <v-btn
                  color="primary"
                  icon
                  :disabled="loading"
                  @click="showDetail(item)">
                  <v-icon>
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </template> -->
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-pagination
                v-model="query.page"
                :length="totalPages"
                :total-visible="7"
                circle
                color="primary" />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { ExportToCsv } from 'export-to-csv'
import ReportsProvider from '@/resources/reports.provider'
import ReportFilters from '../components/ReportFilters.vue'

const ReportsService = new ReportsProvider()

export default {
  components: { ReportFilters },
  data: () => ({
    loading: false,
    exporting: false,
    headers: [
      {
        text: 'เลขที่เวนเดอร์',
        value: 'vendorNo',
        class: 'table-header',
        sortable: false,
        width: '130px'
      },
      {
        text: 'เวนเดอร์',
        value: 'name',
        class: 'table-header',
        sortable: false
      },
      {
        text: 'สถานะ',
        value: 'status',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: 'จำนวนแพ็กเกจทัวร์',
        value: 'tourAmount',
        class: 'table-header',
        align: 'center'
      },
      {
        text: 'ยอดการจองทัวร์',
        value: 'orderAmount',
        class: 'table-header',
        align: 'center'
      },
      {
        text: 'ยอดการขายรวม',
        value: 'orderSales',
        class: 'table-header',
        align: 'right'
      }
      // {
      //   text: '',
      //   value: 'actions',
      //   class: 'table-header',
      //   sortable: false,
      //   align: 'right'
      // }
    ],
    status: [
      {
        text: 'ทั้งหมด',
        value: null
      },
      {
        text: 'เปิดใช้งาน',
        value: 'available'
      },
      {
        text: 'ปิดการใช้งาน',
        value: 'unavailable'
      },
      {
        text: 'รอตรวจสอบ',
        value: 'inspecting'
      }
    ],
    items: [],
    query: {
      sortBy: '',
      sortOrder: true,
      page: 1,
      limit: 10,
      search: '',
      status: null,
      startDate: '',
      endDate: ''
    },
    totalPages: 1
  }),
  watch: {
    'query.sortBy': {
      handler () {
        this.getItems(true)
      },
      deep: true
    },
    'query.sortOrder': {
      handler () {
        this.getItems(true)
      },
      deep: true
    },
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    }
  },
  mounted () {
    this.getItems()
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'ReportList' })
    },
    showDetail (item) {
      this.$router.push({ name: 'VendorsSalesReportDetail', params: { id: item.id } })
    },
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await ReportsService.getVendorsSalesReport({
          ...this.query,
          sortBy: this.query.sortBy || 'createdAt',
          sortOrder: this.query.sortOrder || !this.query.sortBy ? 'desc' : 'asc'
        })

        this.items = data.results
        this.totalPages = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onExport () {
      try {
        this.exporting = true

        const { data } = await ReportsService.getVendorsSalesReport({
          ...this.query,
          page: 1,
          limit: 99999,
          sortBy: this.query.sortBy || 'createdAt',
          sortOrder: this.query.sortOrder || !this.query.sortBy ? 'desc' : 'asc'
        })

        const options = {
          filename: 'vendors-sales-report',
          showLabels: false,
          useKeysAsHeaders: false
        }
        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv([
          {
            vendorNo: 'เลขที่เวนเดอร์',
            name: 'เวนเดอร์',
            status: 'สถานะ',
            tourAmount: 'จำนวนแพ็กเกจทัวร์',
            orderAmount: 'ยอดการจองทัวร์',
            orderSales: 'ยอดการขายรวม'
          },
          ...data.results.map((v) => ({
            vendorNo: v.vendorNo,
            name: v.name,
            status: this.getStatus(v.status),
            tourAmount: v.tourAmount || 0,
            orderAmount: v.orderAmount || 0,
            orderSales: v.orderSales || 0
          }))
        ])
      } catch (error) {
        console.error('onExport', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.exporting = false
      }
    },
    getStatus (status) {
      return this.status.find((v) => v.value === status)?.text || status
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
.table-border {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}
</style>
